<template>
  <static-fullscreen-card :title="!false" @sendHeight="cardHeight = $event">
    <template v-slot:title>
      <div class="d-flex">
        <v-btn v-if="$vuetify.breakpoint.smAndDown" fab small color="primary lighten-3" @click="showCommentsList = true">
          <v-icon>fas fa-ellipsis-v</v-icon>
        </v-btn>
        <v-autocomplete
          v-if="$root.profile.role == 1000"
          :items="$root.dirs['user']"
          item-text="text"
          item-value="value"
          v-model="user_id"
          label=""
          hide-details
          dense
          class="mt-1"
        />

        <span> Ваша переписка</span>
      </div>
    </template>
    <portal to="v-main">
      <v-navigation-drawer v-if="$vuetify.breakpoint.smAndDown" v-model="showCommentsList" absolute temporary>
        <v-app-bar v-if="false" flat dense color="rgba(0,0,0,0)">
          <v-text-field v-if="false" hide-details dense label="Поиск" append-icon="mdi-magnify" color="grey" class="mt-1"></v-text-field>
        </v-app-bar>
        <ChatList
          :items="commentsList"
          @onChange="
            selected = $event;
            showCommentsList = false;
          "
          :height="cardHeight"
        />
      </v-navigation-drawer>
    </portal>
    <div style="height: 100%">
      <splitpanes class="default-theme" style="height: 100%">
        <pane min-size="20" size="20" v-if="!$vuetify.breakpoint.smAndDown" class="border">
          <v-card-text style="overflow: hidden" class="pa-0">
            <v-app-bar v-if="false" flat dense color="rgba(0,0,0,0)" elevate-on-scroll scroll-target="#scrolling">
              <v-text-field v-if="false" hide-details dense label="Поиск" append-icon="mdi-magnify" color="grey" class="mt-1"></v-text-field>
            </v-app-bar>
            <ChatList :items="commentsList" @onChange="selected = $event" id="scrolling" :height="cardHeight" />
          </v-card-text>
        </pane>
        <pane>
          <comment-chatview v-if="id" :id="id" :targetName="targetName" :needUpdate="needUpdateComments" :user_id="user_id" />
        </pane>
      </splitpanes>
    </div>
  </static-fullscreen-card>
</template>

<script>
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";

export default {
  components: { Splitpanes, Pane, ChatList: () => import("./chat/ChatList") },
  data() {
    return {
      cardHeight: 0,
      selected: null,
      id: 0,
      user_id: this.$root.profile.id || 0,
      targetName: null,
      needUpdateComments: 0,
      commentsList: [],
      idEdit: 0,
      showCommentsList: false,
      title: "",
      loading: false,
      defaults: {
        sort: { key: "id", order: "ASC" },
      },
    };
  },
  created() {
    this.$root.title = "Комментарии";
    this.fitchData();
    console.log(this.$root.dirs["user"]);
  },
  computed: {
    target_id() {
      if (!this.selected) return 0;
      return this.commentsList?.[this.selected]?.target_id || 0;
    },
    target_name() {
      if (!this.selected) return null;
      return this.commentsList?.[this.selected]?.target_name || "";
    },
  },
  watch: {
    selected(v) {
      this.id = 0;
      this.$nextTick(() => {
        this.selectGroup(v);
      });
    },
    user_id(v) {
      if (v) this.fitchData();
    },
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    selectGroup(m) {
      this.id = this.commentsList?.[m]?.target_id || 0;
      this.targetName = this.commentsList?.[m]?.target_name || "";
    },
    async fitchData() {
      this.loading = true;
      let resp;
      this.commentsList = [];
      let user = this.user_id;
      //      resp = await this.$axios.post("/api/dinamic/getChatList");
      resp = await this.$axios.post("/accounting/records/report-sql", {
        q: `
SELECT tbl.*,
  (case  
    when tbl.target_name='buying'  then CONCAT('Покупка ',tbl.target_id)
    when tbl.target_name='tasks' then CONCAT('Задача ',tbl.target_id)
    when tbl.target_name='orderGoods' then CONCAT('Заказ товаров №',IFNULL((SELECT code_doc FROM accounting_doc_order_goods WHERE id=tbl.target_id),CONCAT('##',tbl.target_id)))
    when tbl.target_name='rent' then CONCAT('Аренда ',IFNULL((SELECT name FROM rent_objects WHERE id=tbl.target_id),CONCAT('##',tbl.target_id)))
    else CONCAT(tbl.target_name,'##',tbl.target_id)
	end) as name,
  (case when cmm.target_name='tasks' then (select name from tasks where id=cmm.target_id)
    when cmm.target_name='buying' then (select name from buying where id=cmm.target_id)
  else null end)  as 'chat_name',
	cmm.content,cmm.createdon
FROM (
SELECT l.*,
	(SELECT id FROM comments WHERE target_name=l.target_name AND target_id=l.target_id ORDER BY createdon DESC LIMIT 1) AS last_message,
  COUNT(c3.id) as message_count,
  SUM(if(vw.id IS NULL  AND c3.user_id<>${user},  1 ,0)) AS message_count_unviewed
from (
SELECT DISTINCT  c.target_name  , c.target_id,
	IF(c2.id IS NULL,0 ,1) AS is_writer,
	IF(t.id IS NULL,0 ,1) AS is_task,
	IF(tw.id IS NULL,0 ,1) AS is_taskwatch
from comments c
	LEFT JOIN comments c2 ON c2.target_name=c.target_name AND c2.target_id=c.target_id AND c2.user_id=${user}
	LEFT JOIN tasks t ON t.id=c.target_id AND c.target_name='tasks' AND (t.user_id=${user} OR t.createdby_id=${user})
	LEFT JOIN tasks_watchers tw ON tw.id=t.id AND tw.user_id=${user}
	LEFT JOIN buying b ON b.id=c.target_id AND c.target_name='buying' AND (b.user_id=${user} OR b.createdby_id=${user})
	LEFT JOIN buying_watchers bw ON bw.id=t.id AND bw.user_id=${user}
  LEFT JOIN accounting_doc_order_goods og ON og.id=c.target_id AND c.target_name='orderGoods' AND (og.createdby_id=${user})
WHERE c.user_id=${user} OR t.id IS NOT NULL OR tw.id IS NOT null or og.id  IS NOT null
) as l
	LEFT JOIN comments c3 ON c3.target_name=l.target_name AND c3.target_id=l.target_id AND c3.status=1
	LEFT JOIN vieweds vw ON vw.target_name='comments' AND vw.target_id=c3.id  AND vw.user_id=${user}
GROUP BY l.target_name,l.target_id) AS tbl
  LEFT JOIN comments cmm ON cmm.id=tbl.last_message
order by cmm.createdon desc`,
      });
      //console.log("work", resp);
      if (resp.data.data.length > 0) {
        this.commentsList = resp.data.data;
      } else {
        this.afterFitch();
        return;
      }
      this.afterFitch();
      return;
    },
    afterFitch() {
      this.loading = false;
    },
    onClickRow(d) {
      this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.splitpanes__pane {
  background-color: inherit !important;
}

.border {
  border-right: 1px solid grey;
}
.chat-window ::v-deep .v-toolbar__content {
  padding: 0 !important;
}
.chat-window {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
}
.chat-window__container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.chat-window__messages {
  flex-grow: 1;
  overflow: auto;
  min-height: 0;
}

// .v-list-item:after {
//   content: unset;
// }
</style>
